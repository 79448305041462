import { PageProps, graphql, useStaticQuery } from 'gatsby';
import Layout from 'layouts/Layout';

import { contentfulProps } from 'assets/mockData/emptyTypes';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Link from 'quarks/Link';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import type { FC } from 'react';

const SitemapPage: FC<PageProps> = ({ location }) => {
  const {
    pages,
    blogs,
    news,
    cases,
    eguides,
    reports,
    seo,
    infographics,
    tipstools,
    videos,
    webinars,
    podcasts,
    events,
  } = useStaticQuery(graphql`
    query getAllPageSitemapQuery {
      pages: allContentfulTemplatePage(sort: { fields: internalName, order: ASC }) {
        nodes {
          internalName
          slug
        }
      }
      blogs: allContentfulTemplateBlog(
        filter: { blogType: { ne: "News" } }
        sort: { fields: internalName, order: ASC }
      ) {
        nodes {
          internalName
          slug
          blogType
        }
      }
      news: allContentfulTemplateBlog(
        filter: { blogType: { eq: "News" } }
        sort: { fields: internalName, order: ASC }
      ) {
        nodes {
          internalName
          slug
          blogType
        }
      }
      cases: allContentfulTemplateCaseStudy(sort: { fields: internalName, order: ASC }) {
        nodes {
          internalName
          slug
        }
      }
      eguides: allContentfulTemplateEguides(sort: { fields: internalName, order: ASC }) {
        nodes {
          internalName
          slug
        }
      }
      infographics: allContentfulTemplateResource(
        filter: { resourceType: { eq: "Infographics" } }
        sort: { fields: internalName, order: ASC }
      ) {
        nodes {
          internalName
          slug
          resourceType
        }
      }
      reports: allContentfulTemplateResource(
        filter: { resourceType: { ne: "Infographics" } }
        sort: { fields: internalName, order: ASC }
      ) {
        nodes {
          internalName
          slug
          resourceType
        }
      }
      seo: contentfulMetaSeo(contentful_id: { eq: "4wpWRjguGd5HbcOC09lTOr" }) {
        ...contentfulMetaSeo
      }
      tipstools: allContentfulTemplateTipsAndTools(sort: { fields: internalName, order: ASC }) {
        nodes {
          internalName
          slug
        }
      }
      videos: allContentfulTemplateVideos(
        filter: { resourceType: { eq: "Video" } }
        sort: { fields: internalName, order: ASC }
      ) {
        nodes {
          internalName
          slug
        }
      }
      webinars: allContentfulTemplateWebinar(sort: { fields: internalName, order: ASC }) {
        nodes {
          internalName
          slug
        }
      }
      podcasts: allContentfulTemplatePodcast(sort: { fields: internalName, order: ASC }) {
        nodes {
          internalName
          slug
        }
      }
      events: contentfulTemplatePage(slug: { eq: "company/events" }) {
        sections {
          idLink
          component {
            ... on ContentfulComponentHeading {
              id
              heading
            }
          }
        }
      }
    }
  `);

  // TODO event headings
  const eventHeadings =
    events?.sections &&
    events.sections.filter(
      (item: { component: { id: string; heading: string } }) =>
        item?.component?.id && !item?.component?.heading?.includes('Check out the FieldRoutes Blog'),
    );

  return (
    <Layout slug={location.pathname} seo={seo}>
      <Section id={contentfulProps.id}>
        <Flex flexDirection="column" gap="48px" width="100%">
          <Heading
            as="h1"
            textStyle="h3"
            textColor="navy-900"
            fontStyle="italic"
            fontFamily="secondaryFont"
            textAlign="center"
            md={{ textStyle: 'h2', fontStyle: 'italic' }}
            lg={{ textStyle: 'h1', fontStyle: 'italic' }}
          >
            Site Map
          </Heading>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h2"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h2', fontStyle: 'italic' }}
              lg={{ textStyle: 'h2', fontStyle: 'italic' }}
            >
              Pages
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="48px" md={{ flexDirection: 'row' }}>
              <Flex
                flexDirection="column"
                gap="8px"
                width="100%"
                maxWidth="100%"
                md={{ maxWidth: 'calc(50% - 24px)' }}
                lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
              >
                <Text textColor="blue-600" fontSize="h6" lineHeight="h6" textDecoration="none" fontWeight="semiBold">
                  Operations Suite
                </Text>
                {pages?.nodes &&
                  pages.nodes
                    .filter(
                      (page: { slug: string }) =>
                        page?.slug?.includes('operations-suite') && page?.slug !== 'operations-suite',
                    )
                    .map((item: { slug: string; internalName: string }) => (
                      <Link
                        key={item?.slug}
                        href={`/${item?.slug}`}
                        textColor="gray-900"
                        fontSize="textSm"
                        lineHeight="textSm"
                        textDecoration="none"
                        transition="color 200ms ease-in-out"
                        hover={{ textColor: 'blue-600' }}
                      >
                        {item?.internalName}
                      </Link>
                    ))}
              </Flex>
              <Flex
                flexDirection="column"
                gap="8px"
                width="100%"
                maxWidth="100%"
                md={{ maxWidth: 'calc(50% - 24px)' }}
                lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
              >
                <Text textColor="blue-600" fontSize="h6" lineHeight="h6" textDecoration="none" fontWeight="semiBold">
                  Marketing Suite
                </Text>
                {pages?.nodes &&
                  pages.nodes
                    .filter(
                      (page: { slug: string }) =>
                        page?.slug?.includes('marketing-suite') && page?.slug !== 'marketing-suite',
                    )
                    .map((item: { slug: string; internalName: string }) => (
                      <Link
                        key={item?.slug}
                        href={`/${item?.slug}`}
                        textColor="gray-900"
                        fontSize="textSm"
                        lineHeight="textSm"
                        textDecoration="none"
                        transition="color 200ms ease-in-out"
                        hover={{ textColor: 'blue-600' }}
                      >
                        {item?.internalName}
                      </Link>
                    ))}
              </Flex>
              <Flex
                flexDirection="column"
                gap="8px"
                width="100%"
                maxWidth="100%"
                md={{ maxWidth: 'calc(50% - 24px)' }}
                lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
              >
                <Text textColor="blue-600" fontSize="h6" lineHeight="h6" textDecoration="none" fontWeight="semiBold">
                  Solutions
                </Text>
                {pages?.nodes &&
                  pages.nodes
                    .filter((page: { slug: string }) => page?.slug?.includes('solutions') && page?.slug !== 'solutions')
                    .map((item: { slug: string; internalName: string }) => (
                      <Link
                        key={item?.slug}
                        href={`/${item?.slug}`}
                        textColor="gray-900"
                        fontSize="textSm"
                        lineHeight="textSm"
                        textDecoration="none"
                        transition="color 200ms ease-in-out"
                        hover={{ textColor: 'blue-600' }}
                      >
                        {item?.internalName}
                      </Link>
                    ))}
              </Flex>
              <Flex
                flexDirection="column"
                gap="8px"
                width="100%"
                maxWidth="100%"
                md={{ maxWidth: 'calc(50% - 24px)' }}
                lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
              >
                <Text textColor="blue-600" fontSize="h6" lineHeight="h6" textDecoration="none" fontWeight="semiBold">
                  Company
                </Text>
                {pages?.nodes &&
                  pages.nodes
                    .filter((page: { slug: string }) => page?.slug?.includes('company') && page?.slug !== 'company')
                    .map((item: { slug: string; internalName: string }) => (
                      <Link
                        key={item?.slug}
                        href={`/${item?.slug}`}
                        textColor="gray-900"
                        fontSize="textSm"
                        lineHeight="textSm"
                        textDecoration="none"
                        transition="color 200ms ease-in-out"
                        hover={{ textColor: 'blue-600' }}
                      >
                        {item?.internalName}
                      </Link>
                    ))}
              </Flex>
              <Flex
                flexDirection="column"
                gap="8px"
                width="100%"
                maxWidth="100%"
                md={{ maxWidth: 'calc(50% - 24px)' }}
                lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
              >
                <Text textColor="blue-600" fontSize="h6" lineHeight="h6" textDecoration="none" fontWeight="semiBold">
                  Resources
                </Text>
                {pages?.nodes &&
                  pages.nodes
                    .filter((page: { slug: string }) => page?.slug?.includes('resources') && page?.slug !== 'resources')
                    .map((item: { slug: string; internalName: string }) => (
                      <Link
                        key={item?.slug}
                        href={`/${item?.slug}`}
                        textColor="gray-900"
                        fontSize="textSm"
                        lineHeight="textSm"
                        textDecoration="none"
                        transition="color 200ms ease-in-out"
                        hover={{ textColor: 'blue-600' }}
                      >
                        {item?.internalName}
                      </Link>
                    ))}
              </Flex>
              <Flex
                flexDirection="column"
                gap="8px"
                width="100%"
                maxWidth="100%"
                md={{ maxWidth: 'calc(50% - 24px)' }}
                lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
              >
                <Text textColor="blue-600" fontSize="h6" lineHeight="h6" textDecoration="none" fontWeight="semiBold">
                  Support
                </Text>
                <Link
                  href="https://api.fieldroutes.com/index"
                  textColor="gray-900"
                  fontSize="textSm"
                  lineHeight="textSm"
                  textDecoration="none"
                  transition="color 200ms ease-in-out"
                  hover={{ textColor: 'blue-600' }}
                >
                  API Documentation
                </Link>
                <Link
                  href="https://api.fieldroutes.com/index"
                  textColor="gray-900"
                  fontSize="textSm"
                  lineHeight="textSm"
                  textDecoration="none"
                  transition="color 200ms ease-in-out"
                  hover={{ textColor: 'blue-600' }}
                >
                  FieldRoutes Status
                </Link>
                <Link
                  href="https://knowledgebase.fieldroutes.com"
                  textColor="gray-900"
                  fontSize="textSm"
                  lineHeight="textSm"
                  textDecoration="none"
                  transition="color 200ms ease-in-out"
                  hover={{ textColor: 'blue-600' }}
                >
                  Knowledge Base
                </Link>
              </Flex>
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h2"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h2', fontStyle: 'italic' }}
              lg={{ textStyle: 'h2', fontStyle: 'italic' }}
            >
              Blog Posts
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {blogs?.nodes &&
                blogs.nodes.map((item: { slug: string; internalName: string }) => (
                  <Flex
                    key={item?.slug}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/blog/${item?.slug}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.internalName}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h3"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h3', fontStyle: 'italic' }}
              lg={{ textStyle: 'h3', fontStyle: 'italic' }}
            >
              Case Studies
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {cases?.nodes &&
                cases.nodes.map((item: { slug: string; internalName: string }) => (
                  <Flex
                    key={item?.slug}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/resources/case-studies/${item?.slug}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.internalName}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h3"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h3', fontStyle: 'italic' }}
              lg={{ textStyle: 'h3', fontStyle: 'italic' }}
            >
              Eguides
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {eguides?.nodes &&
                eguides.nodes.map((item: { slug: string; internalName: string }) => (
                  <Flex
                    key={item?.slug}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/resources/eguides/${item?.slug}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.internalName}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h3"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h3', fontStyle: 'italic' }}
              lg={{ textStyle: 'h3', fontStyle: 'italic' }}
            >
              Infographics
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {infographics?.nodes &&
                infographics.nodes.map((item: { slug: string; internalName: string }) => (
                  <Flex
                    key={item?.slug}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/resources/infographics/${item?.slug}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.internalName}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h3"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h3', fontStyle: 'italic' }}
              lg={{ textStyle: 'h3', fontStyle: 'italic' }}
            >
              Reports
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {reports?.nodes &&
                reports.nodes.map((item: { slug: string; internalName: string }) => (
                  <Flex
                    key={item?.slug}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/resources/reports/${item?.slug}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.internalName}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h3"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h3', fontStyle: 'italic' }}
              lg={{ textStyle: 'h3', fontStyle: 'italic' }}
            >
              Tips And Tools
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {tipstools?.nodes &&
                tipstools.nodes.map((item: { slug: string; internalName: string }) => (
                  <Flex
                    key={item?.slug}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/resources/tips-and-tools/${item?.slug}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.internalName}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h3"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h3', fontStyle: 'italic' }}
              lg={{ textStyle: 'h3', fontStyle: 'italic' }}
            >
              Videos
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {videos?.nodes &&
                videos.nodes.map((item: { slug: string; internalName: string }) => (
                  <Flex
                    key={item?.slug}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/resources/videos/${item?.slug}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.internalName}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h3"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h3', fontStyle: 'italic' }}
              lg={{ textStyle: 'h3', fontStyle: 'italic' }}
            >
              Webinars
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {webinars?.nodes &&
                webinars.nodes.map((item: { slug: string; internalName: string }) => (
                  <Flex
                    key={item?.slug}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/webinar/${item?.slug}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.internalName}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h3"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h3', fontStyle: 'italic' }}
              lg={{ textStyle: 'h3', fontStyle: 'italic' }}
            >
              Podcasts
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {podcasts?.nodes &&
                podcasts.nodes.map((item: { slug: string; internalName: string }) => (
                  <Flex
                    key={item?.slug}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/resources/podcast/${item?.slug}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.internalName}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h3"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h3', fontStyle: 'italic' }}
              lg={{ textStyle: 'h3', fontStyle: 'italic' }}
            >
              News Articles
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {news?.nodes &&
                news.nodes.map((item: { slug: string; internalName: string }) => (
                  <Flex
                    key={item?.slug}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/company/news/${item?.slug}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.internalName}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" gap="24px">
            <Heading
              as="h1"
              textStyle="h3"
              textColor="navy-900"
              fontStyle="italic"
              fontFamily="secondaryFont"
              md={{ textStyle: 'h3', fontStyle: 'italic' }}
              lg={{ textStyle: 'h3', fontStyle: 'italic' }}
            >
              Events
            </Heading>
            <Flex width="100%" flexDirection="column" flexWrap="wrap" gap="16px 48px" md={{ flexDirection: 'row' }}>
              {eventHeadings &&
                eventHeadings.map((item: { idLink: string; component: { id: string; heading: string } }) => (
                  <Flex
                    key={item?.component?.id}
                    flexDirection="column"
                    gap="8px"
                    width="100%"
                    maxWidth="100%"
                    md={{ maxWidth: 'calc(50% - 24px)' }}
                    lg={{ maxWidth: 'calc((100% - 96px) / 3)' }}
                  >
                    <Link
                      href={`/company/events#${item?.idLink}`}
                      textColor="gray-900"
                      fontSize="textSm"
                      lineHeight="textSm"
                      textDecoration="none"
                      transition="color 200ms ease-in-out"
                      hover={{ textColor: 'blue-600' }}
                    >
                      {item?.component?.heading}
                    </Link>
                  </Flex>
                ))}
            </Flex>
          </Flex>
        </Flex>
      </Section>
    </Layout>
  );
};

export default SitemapPage;
